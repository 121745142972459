import './modules/foundation';
import './modules/owlcarousels';
import './modules/aos';
import './modules/magnific';
import './modules/jquery-steps';

window.formDoSubmit = function() {
  var form = $("#example-advanced-form");
  form.submit();
}

var grecaptchaSiteKey = '6LdrBaoUAAAAACCSa5uomtGmjT8Jyzqm6kFLf0Pp';

var _RECAPTCHA = _RECAPTCHA || {};

_RECAPTCHA.init = function() {
    grecaptcha.ready(function() {
        grecaptcha.execute(grecaptchaSiteKey, {action: 'form'}).then(function(token) {
            if (jQuery('form')[0]) {
                if (jQuery('.grecaptchaToken')[0]) {
                    jQuery('form').find('.grecaptchaToken').remove();
                }

                jQuery('form').append('<input type="hidden" class="grecaptchaToken" name="grecaptchaToken" value="' + token + '" />');
            }
        });
    });
}

_RECAPTCHA.init();

$(document).ready(() => {

    // Back to top button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
        } else {
            $('.scrollup').fadeOut();
        }
    });
    $('.scrollup').click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });

    // Scroll down button (Twice because of the header changing height)
    $('#scrolldown').click(function () {
        var scrolldownTarget = $('#scrolldown-target');
        $('html, body').animate({
            scrollTop: $(scrolldownTarget).offset().top * 0.9
        }, {
            duration: "300",
        });
    });

    // Add class to header once scrolled past X pixels
    function headerClass() {
        var scroll = $(window).scrollTop();
        if (scroll <= 0) {
            $("header").removeClass("scrolled");
        } else {
            $("header").addClass("scrolled");
        }
    }

    $(window).resize(function () {
        headerClass();
    });

    $(window).scroll(function () {
        headerClass();
    });

    headerClass();




});
