import 'magnific-popup';
import 'magnific-popup/src/css/main.scss';

import $ from 'jquery';

$(document).ready(() => {
  $('.popup-youtube, .popup-vimeo, .popup-gmaps, .popup-video, .popup-iframe').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });

  $('div[data-gallery]').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true
    }
  });

  $('a[data-gallery]').on('click', (evt) => {
    evt.preventDefault();
    const el = evt.target.parentElement;
    const galleryTarget = el.dataset['target'];

    $(`div[data-gallery="${galleryTarget}"]`).magnificPopup('open');
  });
});
